<template>
  <div>
    <my-header title="题目解析">
      <template v-slot:right>
        <div></div>
      </template>
    </my-header>
    <div class="body">
      <p class="body-title">医院消毒供应中心管理规范考试</p>
      <div class="topic">
        <div class="superscript">
          <div class="no">{{ pagination.page }}</div>
          <div class="type">
            <span v-if="test.queType == 1">单选题</span>
            <span v-if="test.queType == 2">多选题</span>
            <span v-if="test.queType == 3">问答题</span>
          </div>
        </div>
        <p class="title">{{ test.queContent.title }}:</p>
        <div v-if="test.queType == 1 || test.queType == 2">
          <div
            v-for="(item, index) in test.optionsList"
            :key="index"
            class="list"
          >
            <div style="display: table-cell; width: 5rem">
              <p>{{ item.option }}.</p>
            </div>
            <div style="display: table-cell">
              <p>{{ item.concent }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <span class="left" @click="last"
          ><van-icon name="arrow-left" />上一页</span
        >
        <span class="center"
          ><van-field
            @blur="changePage"
            style="width: 10rem"
            max="100"
            v-model="pagination.fakePage"
            maxlength="3"
            type="number"
            autosize
            :border="true"
          />
          / {{ pagination.total }}</span
        >
        <span class="right" @click="next">下一页<van-icon name="arrow" /></span>
      </div>
      <div class="answer">
        <p>
          提交答案:
          <span style="color: #ff0000" v-if="test.userAnswer">{{
            test.userAnswer.length > 1
              ? test.userAnswer.split(',').sort().toString()
              : test.userAnswer
          }}</span>
        </p>
        <p>
          正确答案:
          <span style="color: #00b923">{{ test.queContent.queAnswer }}</span>
        </p>
        <!-- <p>答题正确率: <span>95%</span></p> -->
        <p>题目解析:</p>
        <div class="area">
          {{ test.queContent.queAnalysis }}
        </div>
        <van-button
          type="default"
          color="linear-gradient(90deg, #FB7A08, #FE6113)"
          class="close"
          @click="$utils.goBack"
          >关闭</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { getTestAnalysis } from '@/api/my/test'
import list from '../list'
export default {
  data() {
    return {
      pagination: {
        fakePage: 1,
        page: 1,
        total: 100,
      },
      test: {
        queType: '1',
        optionsList: [
          {
            option: '',
            concent: '',
          },
        ],
        queContent: { title: '' },
      },
      testList: [],
    }
  },
  mixins: [list],
  created() {
    this.getList()
  },
  computed: {},
  methods: {
    async getList() {
      const res = await getTestAnalysis(
        this.$store.getters.user.id,
        this.$route.query.id
      )
      // console.log(res);
      this.testInfo = res.result
      this.testList = [
        ...this.testInfo.singleQuestions,
        ...this.testInfo.multipleQuestions,
        ...this.testInfo.essayQuestions
      ]
      // console.log(this.testList)
      console.log(this.$route.query.index)
      this.pagination.fakePage = this.$route.query.index
      this.pagination.page = this.$route.query.index
      this.pagination.total = this.testList.length
      this.dealTest()
    },
    last() {
      if (this.pagination.fakePage == 1) {
        return
      }
      this.pagination.fakePage -= 1
      this.pagination.page = this.pagination.fakePage
      this.dealTest()
    },
    next() {
      if (this.pagination.fakePage == this.testList.length) {
        return
      }
      this.pagination.fakePage = Number(this.pagination.page) + 1
      this.pagination.page = this.pagination.fakePage
      this.dealTest()
    },
    dealTest() {
      this.test = this.testList[this.pagination.page - 1]
      this.test.optionsList = []
      for (
        let index = 0;
        index < this.test.queContent.queOptions.length;
        index++
      ) {
        this.test.optionsList.push({
          option: this.optionAllList[index],
          concent: this.test.queContent.queOptions[index].queOption,
        })
      }
      // for (const item of this.test.queContent.queOptions) {
      //   this.test.optionsList.push({
      //     option: this.optionAllList[item.itemOrder],
      //     concent: item.queOption,
      //   })
      // }
    },
    changePage() {
      if (this.pagination.fakePage > this.testList.length) {
        this.pagination.page = this.testList.length
        this.pagination.fakePage = this.testList.length
      } else if (this.pagination.page < 1) {
        this.pagination.page = 1
        this.pagination.fakePage = 1
      } else {
        this.pagination.page = this.pagination.fakePage
      }
      this.dealTest()
    },
  },
}
</script>
<style lang="less" scoped>
.body {
  padding: 0 64px;
  &-title {
    font-size: 54px;
    font-weight: 500;
    color: #ea5514;
  }
  .topic {
    .superscript {
      margin-top: 89px;
      border: 2px solid #ea5514;
      width: fit-content;
      .no {
        background: #ea5514;
        display: inline-block;
        font-size: 3rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 55px;
        width: 3.25rem;
        text-align: center;
      }
      .type {
        display: inline-block;
        font-size: 3rem;
        font-weight: 400;
        color: #ea5514;
        line-height: 55px;
      }
    }
    .title {
      margin: 2.5rem 0 1.5rem 0;
      font-size: 3rem;
      font-weight: 400;
      color: #000000;
    }
    .list {
      margin-top: 56px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #b5b5b6;
      border-radius: 16px;
      padding: 3rem 2rem 32px 3.25rem;
      vertical-align: super;
      p {
        font-size: 3rem;
        font-weight: 400;
        color: #010101;
        // line-height: 55px;
      }
    }
    .area {
      width: 100%;
      height: 645px;
      border: 4px solid #b5b5b6;
      border-radius: 16px;
      font-size: 48px;
      padding: 12px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    padding-bottom: 90px;
    span {
      font-size: 48px;
      font-weight: 400;
      color: #ea5514;
    }
  }
  .answer {
    font-size: 48px;
    font-weight: 400;
    color: #010101;
    p {
      margin-top: 40px;
    }
    .area {
      width: 100%;
      min-height: 320px;
      border: 1px solid #ea5514;
      border-radius: 24px;
      margin-top: 24px;
      padding: 42px 78px 75px 41px;
      font-size: 40px;
      font-weight: 400;
      color: #666666;
    }
  }
  .close {
    margin-top: 60px;
    width: 100%;
  }
  ::v-deep .van-cell {
    display: inline;
    padding: 0;
  }
  ::v-deep .van-field__control {
    display: inline-block;
    width: 132px;
    height: 70px;
    border: 1px solid #b5b5b6;
    color: #b5b5b6;
    border-radius: 4px;
  }
  ::v-deep .van-field__value {
    display: inline;
  }
  ::v-deep .van-field__body {
    display: inline;
  }
}
</style>
